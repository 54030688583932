// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import Rails from "@rails/ujs"

import Turbolinks from "turbolinks"


import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { bindReadMoreButtons, bindReadMoreWraps } from './readMoreModule';
import { readMoreModule } from './readMoreModule';
//require('./customLightbox');
//require("fslightbox");

// Import PhotoSwipe CSS
import 'photoswipe/dist/photoswipe.css';
//import 'photoswipe/dist/default-skin/default-skin.css';

// Import PhotoSwipe JavaScript
//import PhotoSwipe from 'photoswipe';
//import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';


import { adminUtility } from './utility';

import {  siteUi } from './siteUi';
import {  generate } from './generate';
import './posts'; // Import posts.js
import './photos'; // Import photos.js


//import 'https://cdn.jsdelivr.net/npm/@yaireo/tagify@4.17.9/dist/tagify.css';
//import Tagify from 'https://cdn.jsdelivr.net/npm/@yaireo/tagify@4.17.9';


//import '@yaireo/tagify/dist/tagify.css'; // Import other CSS files if needed

//import '@yaireo/tagify/dist/tagify.css'; // Import Tagify styles if needed
//import '@yaireo/tagify';
document.addEventListener('turbolinks:load', () => {
  const input = document.querySelector('.tags');

  if (input && !window.Tagify) {
    // Replace the version number with the desired version
    try {
      const tagifyScript = document.createElement('script');
      tagifyScript.src = 'https://cdn.jsdelivr.net/npm/@yaireo/tagify';
      tagifyScript.onload = function () {
        new Tagify(input);
      };
      document.head.appendChild(tagifyScript);
    }
    catch(err) {
      console.log('Tagifu not loaded via application.js file')
      console.error(err.message);
    }

  }

  // Your other code here
});
Rails.start()
Turbolinks.start()
ActiveStorage.start()

//require("jquery")
//require("easy-autocomplete")

//require("bootstrap")

import "../stylesheets/application";
import 'css/settings' // scss + bootstrap variables
import "@fortawesome/fontawesome-free/css/all"
import 'css/ui'
import 'css/customize'
import 'css/blogs'
import 'css/fun'
import 'css/photos'
import 'css/search'

/*
window.bindQuickAdd = function bindQuickAdd() {
  $(document).on("submit", "form#quickAddForm", function(e){
    e.preventDefault()
    alert('submit')
     $.post($(this).attr("action"), $(this).serialize());
  });
}
*/

window.initSearchExpand = function() {
  $(".search-click-icon").on("click",function(e) {
    e.preventDefault()

    $(this).addClass("d-none")

    $(".search-click").removeClass('d-none')
    $(".search-click-div").removeClass('d-none')
    $(".search-click").focus().select() //focus()
  })
  $(".search-click").on('focus',function(e){
    $(this).closest('.search-click-wrap').find('.search-click-hide').addClass('d-none')
    setTimeout(()=>{
      $(this).addClass('search-click-open')
    },1200)

  })
}

window.jumpToClicked = function(el,offset=-300) {
  let elIndex = $(el).attr('href')
  let targetEl = $(elIndex)
  jumpToEl(targetEl,offset)
}
window.jumpToEl = function(targetEl,offset=-300) {
  let newPos = 0
  if(targetEl !=null)
  {
      newPos = targetEl.offset().top
      newPos = newPos + offset;
  }
  $('html, body').animate({ scrollTop: newPos }, 'fast');
}

window.doInterestsUpdate = function(url,postData,dataType) {
	dataType = dataType || 'json'
	return new Promise((resolve, reject) => {
		$.ajax({ url: url,
		    type: 'POST',
		    dataType: dataType,
		    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
		    data: postData,
		    success: (response) => {
		    	return resolve({response:response})
		    },
		    error: (response) => {
		    	//alert(`Error fetching ${url}`)
		    	console.log(`Error fetching ${url}`)
		    	console.log(response)
		    	return reject({msg:'ERROR ajaxPromise 1',error:response})
		    }
		});
	})

	return reject({msg:`RARE SERVER ERROR fetching {$url}`,error:'ERROR INFO'})
}

window.saveInterest = function(el,updateRolesUrl,incompleteAboutMe=null) {
	let currentLink = $(el)

	let oid = $(el).data('oid')
	let isChecked = (currentLink.data('checked') == 'true' || currentLink.data('checked') == true) ? true : false;
	isChecked = !isChecked // since it is a toggle feature
	// now isChecked is the intended NEW value

	let postData = {list:true,key:'tag',value:'member_of',oid:oid,otype:$(el).data('otype'),value:isChecked}

	let res = window.doInterestsUpdate(updateRolesUrl,postData,'json')
	.then((result) => {
		console.log(result.response)
		let sectionTitle = currentLink.closest('.tagSectionEdit')
		let sectionStatus = sectionTitle.find('.tagSectionStatus')
		if(!isChecked) {
			currentLink.addClass("btn-white").removeClass("btn-primary").removeClass('text-light')
			currentLink.data('checked',"false")
		}
		else {
			currentLink.addClass("btn-primary").addClass('text-light').removeClass("btn-white")
			currentLink.data('checked','true')
		}
		sectionStatus.removeClass('d-none')
		setTimeout(()=>{
			sectionStatus.addClass('d-none')
		},1500)

		if(incompleteAboutMe!=null && incompleteAboutMe) {
			selectAboutMe()
		}
	})
}

window.bindSavingPopups = function() {
  $(".savingPopup").on("click",function(e) {
      let defaultModal = $("#myModal")
      if(defaultModal) {
          defaultModal.modal('hide')
      }
  
      let savingPopupText = $(this).data('saving')
      let modalSelector = $(this).data('modalid')
      $(modalSelector).modal('show')
      if(savingPopupText!=null) {
          $(modalSelector).find('.modal-body').html(savingPopupText)
      }
  })
  }

window.objEngaged = function objEngaged(el) {
  let obj = $(el).closest('.obj')
  if(obj == undefined) {
    console.log('obj wrap not found')
  }
  else {
    let engageUrl = obj.data('engage')
    if(engageUrl == undefined)
    {

    }
    else {
      let res = objLogEngage(engageUrl)
      .then((result) => {
        console.log(result)
      })
    }

  }

}

window.objLogEngage = function objLogEngage(url) {
  let postData = {engaged:1}
  return new Promise((resolve, reject) => {
  $.ajax({ url: url,
          type: 'POST',
          dataType: 'json',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: postData,
          success: (response) => {
            return resolve({response:response})
          },
          error: (response) => {
            //alert(`Error fetching ${url}`)
            console.log(`Error fetching ${url}`)
            console.log(response)
            return reject({msg:'ERROR ajaxPromise 1',error:response})
          }
      });
  })
  return reject({msg:`RARE SERVER ERROR fetching {$url}`,error:'ERROR INFO'})
}


window.initIdleDetect = function initIdleDetect(idleUrl,li,actLoggedStatus,mdStatus) {
    var t;
    var activityLogged = actLoggedStatus;
    var motionDetected = mdStatus;

    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well      
    window.ontouchmove = resetTimer;  // required by some devices 
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeydown = resetTimer;   
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    function functionAfterIdle() {
        // e.g. window.location.href = 'logout.php';

        if(motionDetected && !activityLogged) { //called only once
          
          motionDetected = false // requires more motion to log view
          activityLogged = true
          
          //let the logging take place every 4 seconds
          setTimeout(()=>{
            activityLogged = false
          },4000)

          let res = logIdle(idleUrl)
          .then((result) => {
            console.log(result)
            motionDetected = false
          })
          
          /*
          $.get(idleUrl,function(data,status) {
          motionDetected = false
          },'js');
          */
        }
    }

    function resetTimer() {
      motionDetected = true
        clearTimeout(t);
        t = setTimeout(functionAfterIdle, 2000);  // time is in milliseconds
    }
}

window.logIdle = function logIdle(url) {
  let postData = {blah:1}; //{engaged:1}
  return new Promise((resolve, reject) => {
  $.ajax({ url: url,
          type: 'POST',
          dataType: 'json',
          beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
          data: postData,
          success: (response) => {
            return resolve({response:response})
          },
          error: (response) => {
            //alert(`Error fetching ${url}`)
            console.log(`Error fetching ${url}`)
            console.log(response)
            return reject({msg:'ERROR ajaxPromise 2',error:response})
          }
      });
  })

    return reject({msg:`RARE SERVER ERROR fetching {$url}`,error:'ERROR INFO'})
}

window.setCountdown = function setCountdown(votingClosedData, opts) {
  var flipdown = new FlipDown(votingClosedData, opts)

  // Start the countdown
  .start()

  // Do something when the countdown ends
  .ifEnded(() => {
    console.log('The countdown has ended!');
  });
}

var autoExpand = function (field) {

  // Reset field height
  field.style.height = 'inherit';

  // Get the computed styles for the element
  var computed = window.getComputedStyle(field);

  // Calculate the height
  var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
               + parseInt(computed.getPropertyValue('padding-top'), 10)
               + field.scrollHeight
               + parseInt(computed.getPropertyValue('padding-bottom'), 10)
               + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

  field.style.height = height + 'px';
};

document.addEventListener('input', function (event) {
  if (event.target.tagName.toLowerCase() !== 'textarea') return;
  autoExpand(event.target);
}, false);

window.bindCommentReply = function bindCommentReply() {
  var btn = $(".feedbacker_comments").find('form.new_comment').find('button')
  btn.removeClass('d-none')
  btn.closest('.commentSave').find('.commentSaving').addClass('d-none')

  $(".feedbacker_comments").find('form.new_comment').find('button').on('click',function(e) {
    //$(this).attr("disabled", "disabled");
    $(this).closest('.commentSave').find('.commentSaving').removeClass('d-none')
    $(this).addClass('d-none')    

    setTimeout(()=>{
      $(this).removeClass('d-none')
      //$(this).removeAttr("disabled");
    },6000)
  })
  $('.comment-reply').off('click').on('click', function(e) {
    e.stopPropagation()
    e.preventDefault()
    $(this).closest('.comment').find('.reply-form').toggle()
  }) 
}

window.bootstrapExtras = function bootstrapExtras() {
/*
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
*/
  $(function () {
        //$('[data-toggle="tooltip"]').tooltip()
        //$('[data-toggle="popover"]').popover()
        //$('[data-bs-toggle="tooltip"]').tooltip()
        //$('[data-bs-toggle="popover"]').popover()
        

    })
try {
  //$('[data-bs-toggle="tooltip"]').tooltip()
  //$('[data-bs-toggle="popover"]').popover({trigger: 'focus'})
  
  $('[data-bs-toggle="tooltip"]').tooltip({sanitize:false,html:true,boundary:'window'})
	$('[data-bs-toggle="popover"]').popover({sanitize:false,html:true,boundary:'window',trigger: 'focus'})

} catch (err) {

}


//  $('[data-toggle="popover"]').popover({sanitize:false,html:true,boundary:'window',trigger: 'focus'})

}

document.addEventListener("turbolinks:load", function() {
//    $(function () {
//        $('[data-toggle="tooltip"]').tooltip()
//        $('[data-toggle="popover"]').popover()
//    })
  bindCommentReply()
  bootstrapExtras()
  
  //bindQuickAdd()
})

import "chartkick/chart.js"
import "animate.css/animate.css"
require("trix")
require("@rails/actiontext")
require("easy-autocomplete")
require("packs/jquery.connections")
//require("packs/siteUi") //instead we are now importing

import hljs from 'highlightjs';

$(document).ready(function() {
  hljs.initHighlightingOnLoad();
  //global.hljs = require('highlightjs/highlight.pack.js');
});

window.updateHighlightJs = function updateHighlightJs() {
  hljs.initHighlightingOnLoad(); //highlightAll(); 
}

window.highlightJsRender = function highlightJsRender(text) {
  let html = hljs.highlightAuto(text).value
  return html
}

//const sleep = ms => new Promise(r => setTimeout(r, ms));
window.sleep = function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

window.animateCSS = function animateCSS(element, animation, prefix = 'animate__') {
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    if(node!=null) {
      node.classList.add(`${prefix}animated`, animationName);
      node.addEventListener('animationend', handleAnimationEnd, {once: true});
    }
  });
}


window.changeMainProgressBar = function changeMainProgressBar(progressVal,runAgain=false) {
  var progressIncrAmt = 2

  //$("#mainLoadingDiv").css("display", "block");
  progressVal+=progressIncrAmt
  $("#mainLoadingDiv").find('.progress-bar').css('width', progressVal+'%').attr('aria-valuenow', progressVal); 
  if(runAgain) {
    setTimeout(()=>{
        changeMainProgressBar(progressVal,progressVal < 100+progressIncrAmt)
    },100)
  }
}


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch(error => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  const installButton = document.getElementById('install-button');
  if(installButton) {
    installButton.style.display = 'block';

    installButton.addEventListener('click', () => {
      // Hide the install button
      installButton.style.display = 'none';
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        deferredPrompt = null;
      });
    });
  }
});



/*
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
window.jQuery = $;
window.$ = $;
global.$ = require('jquery')
*/

/*
import 'lightbox2/src/css/lightbox.css';
// Import Lightbox2 JavaScript
import 'lightbox2/src/js/lightbox.js';

$(document).ready(function() {
    lightbox.option({
        'resizeDuration': 200,
        'wrapAround': true,
        'alwaysShowNavOnTouchDevices': true,
    });
});
*/